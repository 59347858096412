p {
    font-weight: 500 !important;
    font-family: "Public Sans", sans-serif;
    font-size: 1em;
    line-height: 1.8;
}

tbody {
    --bs-table-bg: rgba(247, 247, 248, 1);
}

thead {
    --bs-table-bg: rgba(196, 196, 196, 1);
}

body {
    font-family: "Public Sans", sans-serif;
}

html,
body {
    font-family: "Public Sans", sans-serif;
    max-width: 100%;
    overflow-x: hidden;
}

.timeline_area {
    position: relative;
    z-index: 1;
}

.single-timeline-area {
    position: relative;
    z-index: 1;
    padding-left: 180px;
}

.single-timeline-area .timeline-date {
    position: absolute;
    width: 180px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 60px;
}


.single-timeline-area .timeline-date::after {
    position: absolute;
    width: 1px;
    height: 100%;
    content: "";
    background-color: #ebebeb;
    top: 0;
    right: 30px;
    z-index: 1;
}

.single-timeline-area .timeline-date::before {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #36528B;
    content: "";
    top: 50%;
    right: 26px;
    z-index: 5;
    margin-top: -5.5px;
}

.single-timeline-area .timeline-date p {
    margin-bottom: 0;
    color: #020710;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}

.single-timeline-area .single-timeline-content {
    position: relative;
    z-index: 1;
    padding: 30px 30px 25px;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    border: 1px solid #ebebeb;
}


.single-timeline-area .single-timeline-content .timeline-icon {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 30px;
    height: 30px;
    background-color: #36528B;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    text-align: center;
    /* max-width: 30px; */
    border-radius: 50%;
    margin-right: 15px;
}

.single-timeline-area .single-timeline-content .timeline-icon i {
    color: #ffffff;
    line-height: 30px;
}

.single-timeline-area .single-timeline-content .timeline-text h6 {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-timeline-area .single-timeline-content .timeline-text p {
    font-size: 13px;
    margin-bottom: 0;
}

.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
    background-color: #020710;
}

.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
    color: #3f43fd;
}

.contact-icon-container {
    /*  width: 8%; */
    margin-right: 1rem;
}

.contact-icon h5 {
    font-weight: 800;
}

.contact-icon-box {

    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    color: #ffffff;
    background: #36528B;
}

.contact-icon-box svg {
    text-align: center;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    position: relative;
    top: 5px;
}

.contact-title {
    /* margin-top: auto;
    margin-bottom: auto; */
    align-items: center;
    display: flex;
    color: #000;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.navbar-toggler:focus {

    box-shadow: none !important;
}


@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 575px) {

    .single-timeline-area .single-timeline-content {
        padding: 20px;
    }

    .single-timeline-area .timeline-date {
        width: 110px;
        font-size: 12px;
    }

    .single-timeline-area {
        padding-left: 100px;
    }

    .custom-heading-shadow {
        /* display: none; */
    }

    .contact-icon .contact-icon-container {
        /*   width: 20%; */
    }

    .contact-left {
        margin-bottom: 50px;
    }
}

.row .no-x-gutter {
    --bs-gutter-x: 0rem;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}


.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    z-index: 10000 !important;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.back-to-top-button.visible {
    opacity: 1;
    visibility: visible;
}


/* sliding nav */
.sticky {
    color: white;
    background: #203965;
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08);
    height: auto;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 999
}

.sliding-nav {
    transition: 0.3s all linear;
    color: black !important;
    /* background: rgba(13, 174, 86, 0.48); */
    box-shadow: rgba(18, 18, 18, 0.08) 0px 4px 40px 0px;
    height: auto;
    /* opacity: 0.9; */
    width: 100vw;
    position: fixed;
    top: 0px;
    z-index: 999;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08);
}


.dropdown-big-custom:hover,
.dropdown-big-custom:focus,
.dropdown-big-custom.active-nav {
    border-left: 2px solid #212c74;
    background: rgba(13, 83, 174, 0.08) !important;
    color: #214474 !important;
}

.swiper-pagination-progressbar {
    background: rgba(251, 169, 29, 0.16) !important;
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    position: inherit !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #214474 !important;
}

.gallery-pagination ul {
    gap: 20px
}

.gallery-pagination ul li.active {
    color: #214474 !important;
    font-weight: 700;
}

.custom-button {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    font-weight: 600 !important;
}

.custom-button.secondary:hover,
.custom-button.secondary:focus {
    color: #224472;
    border: 1px solid #224472 !important;
    background-color: #f4fff9 !important;
    font-weight: 700;
}


.custom-button.secondary {
    color: #224472 !important;
    border: 1px solid #224472;
    background-color: #ffffff;
    font-weight: 700;
}

.custom-button.secondary:hover {
    background: #0b9f4d;
    color: #000;
}

.swipper-btn {
    border-radius: 100px;
    opacity: 0.36;
    background: rgba(255, 255, 255, 0.16) !important;
    color: #fff;
    border-radius: 100% !important;
    border: none !important;
    width: 60px;
    height: 60px;
    border: none;
    /* margin: 10px; */
}

.swipper-arrow-left {
    margin-left: 10px;
}

.swipper-arrow-right {
    margin-right: 10px;
}


/* slider css */


.swiper-pagination {
    text-align: left !important;
}

.swiper-pagination-bullets-dynamic {
    transform: unset !important;
    height: 100px;
}

.swiper-pagination-bullets {
    top: 10% !important;
    left: 3% !important;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background: #fff;
    left: 0px !important;
}

.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 26px;
    height: 15px;
    background: #fff;
    border-radius: 10px !important;
}


.videos {
    margin: 20px auto;
    max-width: 700px;
    border-radius: 20px;
}

/* Individual video container */
.video-wrap {
    position: relative;
    max-width: 700px;
    width: 100%;
    margin-bottom: 10px;

}

/* video poster frame or placeholder image */
.video-wrap .thumb-video {
    max-width: 700px;
    width: 100%;
}

/* SVG play button container */
.video-wrap .play-btn {
    position: absolute;
    max-width: 700px;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.video-wrap .play-btn svg {
    transition: all .2s ease;
    fill: #fff;
}

.video-wrap .play-btn:hover svg,
.video-wrap .play-btn:focus svg {
    cursor: pointer;
    fill: #1785e6;
}

.product-title-link {
    text-decoration: none;
}

.product-title-link:hover {
    text-decoration: underline;
}

.floating-tool {
    cursor: pointer;
    position: fixed;
    z-index: 99999;
    bottom: 3vh;
    text-decoration: none;
    font-size: 14px;
    left: 0;
    font-weight: 800;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.floating-tool-mobile {
    cursor: pointer;
    position: fixed;
    display: block;
    top: 90vh;
    z-index: 100000000000;
    text-decoration: none;
    font-size: 14px;
    left: 0px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}


/* adjust your icon size, add different breakpoints as needed */
@media screen and (max-width: 600px) {
    .video-wrap .play-btn {
        width: 70px;
        height: 70px;
    }
}

@media only screen and (max-width: 576px) {
    p {
        font-size: 0.9em !important;
    }

    .swipper-nav .swipper-arrow-left {
        background: none !important;
        color: #224474;
    }

    .swipper-nav .swipper-arrow-right {
        background: none !important;
        color: #224474;
    }
}